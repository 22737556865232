<template>
  <div>
    <div class="header" :style="!showSearch? 'height:300px':'' ">
      <div class="center land-box" style="margin-top:0;margin-bottom: 0;">
        <div class="head-top">

          <div class="logo">
            <a data-v-41558824="" href="/" class="router-link-active" style="display: inline-block;width:350px;">
              <img data-v-41558824="" src="../assets/img/home/logo.jpg" style="width: 100%">
              </a></div>
          <div  class="head-intro"  >
            <p  class="head-top-mark">{{todaydata}}</p>
          <p style="font-weight: 600;margin-right:70px "> 德阳市 {{weather}}℃ </p>
        </div>
        </div>
      </div>
        <div class="center land-box" style="margin-top:0;margin-bottom: 0;">
          <el-row :gutter="24">
            <el-col :span="16">
              <ul class="nav" style="background: transparent; border-bottom: 1px solid hsla(0,0%,100%,.2);-webkit-box-shadow: 0 2px 10px rgba(1,64,153,.1);box-shadow: 0 2px 10px rgba(1,64,153,.1);
    ">
                <li v-for="(item, index) in navList" :key="index" @click="funyuming(item.url, index)"
                    :class="index == navStore.activeIndex ? 'active' : ''">
                  <a class="link-a"> {{ item.title }}</a>
                </li>
                <li class="login" v-if="!isLogin">
                  <RouterLink class="link-a" to="/login"><i style="color: whitesmoke" class="ti ti-user"></i>登录</RouterLink>
                </li>
                <li class="login" v-if="isLogin" style="color: whitesmoke">
                  <RouterLink class="link-a" to="/my/userinfo" >个人中心</RouterLink>
                </li>
              </ul>
            </el-col>
            <el-col :span="6" :offset="1" >
              <!--   <div style="text-align: center;font-size: 42px;margin-top:50px; color: whitesmoke;">德阳市土地大市场</div>
                <div style="text-align: center;font-size: 22px;margin-bottom: 30px;color:whitesmoke;">(德阳市土地二级市场交易平台)</div>-->
              <div class="" v-if="showSearch"  style="    margin-top: 17px;">
                <!-- <el-select v-model="searchType">
                    <el-option v-for="item in landTypes" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select> -->
                <el-row :gutter="24">
                  <el-col :span="20" :offset="6"><div class="grid-content bg-purple">
                    <el-input v-model="params.keyword" placeholder="搜索关键词快速找地"  class="search">
                      <template v-slot:append>
                        <el-button type="primary" icon="el-icon-search"  @click="searchLand" />
                      </template>
                    </el-input></div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>

        </div>
    </div>
    <div class="center home-box" style="background: white; border-radius: 5px 0 0 5px;" v-if="!is_show">
      <div style="">
        <el-row :gutter="20"  v-if="showSearch"  style="padding: 15px 0px ">
          <el-col :span="4">
            <div class="selrow">
              分类查找
            </div>
          </el-col>
          <el-col :span="4" style="  margin-top: 9px;">
            <el-select v-model="params.land_type" placeholder="土地性质" clearable>
              <el-option v-for="item in land_type" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" style="  margin-top: 9px;">
            <el-select v-model="params.land_area" placeholder="所属行政区" clearable>
              <el-option v-for="item in land_area" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" style="  margin-top: 9px;">
            <el-select v-model="params.transaction_way" placeholder="交易方式" @change="clearTansWay" clearable>
              <el-option v-for="item in transaction_way" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" style="  margin-top: 9px;">
            <el-select v-model="params.demand_purpose" placeholder="土地用途" clearable>
              <el-option v-for="item in demand_purpose" :key="item.value" :label="item.label" :value="item.value"  :class="params.transaction_way=='rent'?'':(item.label=='工业场地' || item.label=='工业厂房' )?'hides': ''">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" style="  margin-top: 9px;">
            <el-button  @click="searchLand" style="width: 80%">开始查找</el-button>
          </el-col>
        </el-row>
      </div>

    </div>
  </div>
</template>

<script>
import { useDict } from '@/hooks/dict';
import { useNavStore } from '@/stores/nav';
import {useSearchStore} from '@/stores/search';
import CommonService from '../service/CommonService';
import { RouterLink } from 'vue-router';

export default {
    name: "LandHeader",
    props: {
        showSearch: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            searchStore: useSearchStore(),
            navStore: useNavStore(),
          weather: 0,
          is_show: false,
          todaydata: "",
            params: {
                keyword: "",
                land_type: "",
                land_area: "",
                transaction_way: "",
                demand_purpose: "",
            },
            navList: [
                { title: "首页", url: "/" },
                // { title: "供给用地", url: "/supply" },
                // { title: "需求用地", url: "/demand" },
                // { title: "存量土地", url: "/stock" },
                { title: "交易大厅", url: "/tradingHall" },
                // { title: "数据分析", url: "/landDataHome" },
                { title: "地图找地", url: "/map" },
                { title: "通知公告", url: "/information" },
                { title: "服务中心", url: "/services" },
                // { title: "个人中心", url: "/my/userinfo" },
                // { title: "登录", url: "/login" },
                // { title: "集团概括", url: "/jituangaikuo" },
                // { title: "集团产业", url: "/keji" },
                // { title: "集团新闻", url: "/xinwen" },
                // { title: "招聘详情", url: "/zhaopinxiangqing0_0" },
                // { title: "联系我们", url: "/lianxi" },
                // { title: "招聘信息", url: "/zhaopinxinxi0" },
            ],
            logoimg: require("../assets/img/lALPDhmOw39u8LZszQFq_362_108.png_720x720q90g.jpg"),
        };
    },
    created() {
      let tmpArr = ['tradingHall', 'supply', 'stock', 'demand'];
      if(this.$route.matched[0].name &&  tmpArr.includes(this.$route.matched[0].name))
      {
        this.is_show=true;
      }else
      {
        this.is_show=false;
      }

      if (this.$route.matched[0].name === 'index') {
        this.navStore.$patch({
          activeIndex: 0
        });
      }
      let  that=this;
      var httpRequest = new XMLHttpRequest();
      httpRequest.open('GET', 'https://restapi.amap.com/v3/weather/weatherInfo?key=eabe30a42a1b15b3984831d63f519dc1&city=510600&extensions=base&output=JSON&randomStr=BwBTtyEtwAdb', true);//第二步：打开连接  将请求参数写在url中  ps:"http://localhost:8080/rest/xxx"
      httpRequest.send();
      httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState == 4 && httpRequest.status == 200) {
          var json = httpRequest.responseText;
         var  resdata =eval('(' + json + ')');
          if(resdata.infocode==10000)
          {
            that.weather=resdata.lives[0].temperature;
          }
        }
      }


      const date = new Date();
     this.todaydata=this.formatDateTime( 'yyyy年MM月dd日  星期W',date);

    },
    computed: {
        land_type() {
            const { land_type } = useDict("land_type");
            return land_type.value;
        }
        ,land_license_type() {
        const { land_license_type } = useDict("land_license_type");
        return land_license_type.value;
      },
        land_area() {
            const { land_area } = useDict("land_area");
            return land_area.value;
        },
        transaction_way() {
            const { transaction_way } = useDict("transaction_way");
            console.log(111)
            console.log(transaction_way)
            return transaction_way.value.slice(0,5);
        },
        land_purpose() {
            const { land_purpose } = useDict("land_purpose");
            return land_purpose.value;
        },
       demand_purpose() {
            const { demand_purpose } = useDict("demand_purpose");
            return demand_purpose.value;
        },
        isLogin() {
            return CommonService.isLogin();
        },
    },
    methods: {
      clearTansWay(){
        console.log(this.params)
        if(this.params.transaction_way!='rent')
        {
          if(this.params.demand_purpose=='transportation_land' || this.params.demand_purpose=='water_land')
          {
            this.params.demand_purpose='';
          }
        }
      },
      formatDateTime(f, d) {
  let fmt = f || 'yyyy-MM-dd hh:mm:ss'
  let date = d ? new Date(d) : new Date()
  let ret
  let weekArr = ['日', '一', '二', '三', '四', '五', '六']
  const opt = {
    'y+': date.getFullYear().toString(), // 年
    'M+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'h+': date.getHours().toString(), // 时
    'm+': date.getMinutes().toString(), // 分
    's+': date.getSeconds().toString(), // 秒
    'W+': date.getDay(), // 星期
    'Q+': Math.ceil((date.getMonth() + 1) / 3) // 季度   // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(
          ret[1],
          ret[1].length == 1
              ? k === 'W+' || k === 'Q+'
                  ? weekArr[opt[k]]
                  : opt[k]
              : opt[k].padStart(ret[1].length, '0')
      )
    }
  }
  return fmt
},
        funyuming(msg, index) {
            this.navStore.$patch({
                activeIndex: index
            });
            localStorage.setItem('nav_head_index', index);
            this.$router.push(msg);
        },
        funRouter() {
            this.$router.push("/keji");
        },
        searchLand() {
        // console.log(this.params);
            this.searchStore.$patch({
                ...this.params
            });
            console.log(this.params)
            const index = this.navList.findIndex(item => item.url == "/tradingHall");
            this.navStore.$patch({
                activeIndex: index
            });
            this.$router.push({path:"/tradingHall"});
        },
    },
    components: { RouterLink }
};
</script>
<style lang="scss">
.selrow{
  text-align: center;
  line-height:40px;
  font-weight: bold;
  padding-top: 6px;
  border-radius: 5px 0 0 5px;
  color: #fff;
  background: url('../assets/img/fcf03.png') 50% no-repeat;
  background-size: 100% 100%;
  height: 52px;
  background-color: #fff;
}
.header {
    width: 100%;
    height: 300px;
      background: url("../assets/img/bg_new.webp") 50% no-repeat;
  // background: url("../assets/img/bg_new 1 (2).png") 50% no-repeat;
    background-size: 100% auto;
    background-position: 0 67%;
    /* position: relative; */

    ul.nav {
        width: 100%;
        display: flex;
        justify-content: space-between;

        border-bottom: 1px solid #333;
        padding: 8px 0 4px 0;

        li {
            list-style-type: none;
            cursor: pointer;

            .link-a {
                display: block;
                color: whitesmoke;
                text-align: center;
                line-height: 50px;
                font-size: 22px;
                cursor: pointer;
            }

            &.active {
                .link-a {
                    color: #1e88fb;
                }
            }
        }
    }

    .header-search {
        width: 560px;
        margin: 15px auto 25px auto;
        display: flex;
        flex-direction: row;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #ebeef5;
        overflow: hidden;

        .el-select {
            width: 100px;
        }

        .el-input__inner {
            border: none;
        }
    }
}
.head-top
{

  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 1200px;
  padding: 24px 0 0;
  margin: 0 auto;    color: #fff;
}
.head-top-mark {
  position: absolute;
  top: 29px;
  right: 12px;
  z-index: 1;
  font-size: 18px;
  font-weight: 600;
}
.head-intro {
  margin-top: 19px;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: horizontal;
   -webkit-box-direction: normal;
   -ms-flex-direction: row;
   flex-direction: row;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   font-size: 14px;
 }
.header ul.nav li.active .link-a {
  color: white;
  font-weight: bold;
  border-bottom: 4px solid white;
  font-size: 24px;
}
.hides{
  display: none;
}
</style>
